import React from "react"
import { Modal, Form, Button, InputGroup, FormControl } from "react-bootstrap"
import "../styles/main.scss"
import mail from "../images/mail.svg"
import arrow from "../images/arrow.svg"
import { translate } from "../utils"

const ModalSideTwo = () => {
  return (
    <div className="modal-right-wrap">
      <Modal.Dialog>
        <div className="d-flex d-md-none back-btn">
          <a href={"#"}>
            <img
              width={15.07}
              height={6.312}
              className="img-fluid"
              src={arrow}
              alt=""
            />
          </a>
        </div>
        <Modal.Header>
          <Modal.Title>
            <span>Add a team member</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                  <img
                    width={16}
                    height={8}
                    className="img-fluid"
                    src={mail}
                    alt=""
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Email"
                aria-label=""
                aria-describedby="basic-addon1"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                  <img
                    width={16}
                    height={8}
                    className="img-fluid"
                    src={mail}
                    alt=""
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Email"
                aria-label=""
                aria-describedby="basic-addon1"
              />
            </InputGroup>
            <Button variant="primary" type="submit" className="mt-4">
              {translate('send an invitation')}
            </Button>
          </Form>
        </Modal.Body>
      </Modal.Dialog>
    </div>
  )
}

export default ModalSideTwo
